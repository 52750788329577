import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { userActions } from '@ducks/user';
import { appUrl } from '@src/helpers/navigation';
import ExternalLandingPage from './ExternalLandingPage';

const mapDispatchToProps = dispatch => ({
  setUser: data => {
    dispatch(userActions.setActiveUser(data));
  },
  clearUser: () => {
    dispatch(userActions.clearUser());
  }
});

const redirectUrlFromState = () => {
  try {
    const currentUrl = new URL(document.location);
    const state = JSON.parse(atob(currentUrl.searchParams.get('state')));
    if (state.redirectUrl && state.redirectUrl.includes(currentUrl.origin)) {
      return state.redirectUrl;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const Home = props => {
  const { isLoggedIn } = props;
  const isLoggedInDetermined = isLoggedIn === false;
  let component = null;

  const redirectUrl = redirectUrlFromState();
  if (redirectUrl) {
    // Reach router redirect routing will not work here
    // Sometimes we need to redirect outside of defined routes, e.g. /kom-i-gang (after purchase)
    window.location.href = redirectUrl;
  } else if (isLoggedIn) {
    component = <Navigate to={appUrl} />;
  } else if (isLoggedInDetermined) {
    component = <ExternalLandingPage />;
  }

  return component;
};

Home.propTypes = {
  setUser: PropTypes.func,
  clearUser: PropTypes.func,
  isLoggedIn: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn
});

const container = connect(mapStateToProps, mapDispatchToProps)(Home);

export default container;
